import { Link } from "gatsby"
import React from "react"
import logoSvg from "../../images/logo-black.svg"

// eslint-disable-next-line react/prop-types
const Sec = ({ children }) => (
  <p className="font-bold mb-1 md:mb-4">{children}</p>
)

export const Footer = () => {
  return (
    <footer className="bg-gray-50 pb-4 pt-12 text-sm select-none">
      <section className="flex flex-col md:flex-row container mx-auto mb-12 px-4 md:px-0">
        <div className="flex-1 flex flex-col">
          <div className="flex-1 flex">
            <img
              src={logoSvg}
              alt="CMK Logo"
              className="h-12 mb-4 w-32 rounded-lg"
            />
            <p className="font-bold ml-4">
              <span className="text-75">Celebrity Mobile Kitchen</span>
              <br />
              <span className="opacity-75">Culinary Institute</span>
            </p>
          </div>
          <div className="px-4">
            <li className="block opacity-75 mb-2 whitespace-nowrap">
              Whatsapp: +234 0803-578-2455
            </li>
            <li className="block opacity-75 mb-2 whitespace-nowrap">
              Lagos: +234 0702-660-0011
            </li>
            <li className="block opacity-75 mb-2 whitespace-nowrap">
              Port-Harcourt: +234 0702-660-0012
            </li>
          </div>
        </div>

        <nav className="flex flex-col md:flex-row w-full md:w-2/3 pt-0">
          <div className="flex-1 mt-6 md:mt-0">
            <Sec>Campuses</Sec>

            <p className="mb-4 opacity-75">
              <div>Excellent Centre</div>
              <p>39, Osolo way Ajao Estate, Lagos </p>
            </p>
            <p className="opacity-75">
              <div>Creative Centre</div>
              <p>
                36 Trans Amadi Street Rumuobiakani, <br /> Oginigba Port
                Harcourt.
              </p>
            </p>
          </div>

          <div className="flex-1 mt-6 md:mt-0">
            <Sec>Quick links</Sec>
            <li className="hover:underline block opacity-75 mb-2">
              <Link to="/about">About CMK</Link>
            </li>
            <li className="hover:underline block opacity-75 mb-2">Admission</li>
            <li className="hover:underline block opacity-75 mb-2">Courses</li>
          </div>

          <div className="flex-1 mt-6 md:mt-0">
            <Sec>Socials & Enquiries</Sec>
            <li className="block opacity-75 mb-2">
              <a
                referrerPolicy="no-referrer"
                className="hover:underline"
                href="https://web.facebook.com/cmkculinary/"
              >
                Facebook
              </a>
            </li>
            <li className="block opacity-75 mb-2">
              <a
                referrerPolicy="no-referrer"
                className="hover:underline"
                href="https://instagram.com/cmkculinary/"
              >
                Instagram
              </a>
            </li>
            <li className="block opacity-75 mb-2">
              <a
                referrerPolicy="no-referrer"
                className="hover:underline"
                href="https://twitter.com/cmkculinary"
              >
                Twitter
              </a>
            </li>
          </div>
        </nav>
      </section>

      <div className="flex flex-col md:flex-row px-4 justify-between container mx-auto py-4 text-gray-800 text-opacity-75">
        <div>
          Copyright &copy; 2013 - {new Date().getFullYear()} CMK Culinary
          Institute
        </div>

        <div>
          Made with{" "}
          <span role="img" aria-label="love">
            💚
          </span>{" "}
          by Wigxel Corp
        </div>
      </div>
    </footer>
  )
}
