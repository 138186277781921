const Theme = {
  primary: "olivedrab",
  colors: {
    primary: "#FF0019",
    _1: "#1D293F",
    _2: "#20E9BC",
    _3: "#F2C94C",
    _4: "#FF374F",
    _5: "#E6F0EB", // gray like green
    _6: "#F4F7F7",
    _7: "#FEE2E2",
    _8: "#9393AA",
    _9: "#323333", // text color
  },
  radio: {
    unchecked: "#eee",
    checked: "#FF0019",
    shadow: "rgba(0, 0, 0, .26)",
    pill: { hover: "#f8f8f8" },
  },
  toggle: {
    inside: "whitesmoke",
    active: "#00A75D",
    inactive: "white",
  },
}

export default Theme
